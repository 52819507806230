import client from "@/api";
import store from "@/store";
import {
  _rotaOrders,
  _rotaOrdersDetails,
  _rotaOrdersPayments,
  _rotaOrdersStatus,
  _rotaOrdersCSV,
} from "@/api/_caminhosApi";
import { arrayToFilterString } from "@/utils/array";

export function getCSV(from, to) {
  store.commit("interacoes/SET_LOADING", true);
  client
    .get(_rotaOrdersCSV(), {
      params: {
        from,
        to,
      },
      responseType: "blob",
    })
    .then((resp) => {
      if (resp.status === 200) {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "orders.csv");
        document.body.appendChild(link);
        link.click();
      }
    })
    .catch((erro) => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function fetchOrders(from, to, filters) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("orders/RESET_ORDERS", null);
  client
    .get(_rotaOrders(), {
      params: {
        from,
        to,
        filters: arrayToFilterString(filters),
      },
    })
    .then((resp) => {
      store.commit("orders/SET_ORDERS", resp.data);
    })
    .catch((erro) => {
      console.error(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function fetchOrdersDetails(from, to, qtd, page, filters) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("orders/RESET_ORDERS", null);
  client
    .get(`${_rotaOrdersDetails()}`, {
      params: {
        from,
        to,
        per_page: qtd,
        page: page,
        filters: arrayToFilterString(filters),
      },
    })
    .then((resp) => {
      store.commit("orders/SET_ORDERS_DETAILS", resp.data);
    })
    .catch((erro) => {
      console.error(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function fetchOrdersPayments(from, to, filters) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("orders/RESET_ORDERS", null);
  client
    .get(_rotaOrdersPayments(), {
      params: {
        from,
        to,
        filters: arrayToFilterString(filters),
      },
    })
    .then((resp) => {
      store.commit("orders/SET_ORDERS_PAYMENTS", resp.data);
    })
    .catch((erro) => {
      console.error(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
export function fetchOrdersStatuses(from, to, filters) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("orders/RESET_ORDERS", null);
  client
    .get(_rotaOrdersStatus(), {
      params: {
        from,
        to,
        filters: arrayToFilterString(filters),
      },
    })
    .then((resp) => {
      store.commit("orders/SET_ORDERS_STATUSES", resp.data);
    })
    .catch((erro) => {
      console.error(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
