<template>
  <div class="line-statuses">
    <div class="line-statuses-bar">
      <div
        class="line-statuses-bar__item"
        v-for="(status, index) in statuses"
        :key="index"
        :style="{ width: (status.qtd_pedidos * 100) / total + '%' }"
      >
        <span class="line-statuses-bar__item--color" :style="{ backgroundColor: setColor(status.status) }"></span>
        <div class="line-statuses-bar__item--status">{{ ((status.qtd_pedidos * 100) / total).toFixed() }}%</div>
      </div>
    </div>

    <div class="line-statuses__status-name">
      <div class="line-statuses__status-name--content" v-for="(status, index) in statuses" :key="index">
        <span class="line-statuses__status-name--color" :style="{ backgroundColor: setColor(status.status) }"></span>
        {{ status.statusDescription }}
      </div>
    </div>
  </div>
</template>

<script>
import { setColor } from "@/utils/status";
export default {
  props: ["statuses", "total"],
  methods: {
    setColor
  }
};
</script>

<style lang="scss" scoped>
.line-statuses {
  margin: 40px 0;
  &__status-name {
    margin-top: 44px;
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
    }
    &--content {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      gap: 14px;
      line-height: 19px;
      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }
    &--color {
      background-color: #2c2c2c;
      min-width: 19px;
      min-height: 19px;
      border-radius: 50%;
    }
  }
}

.line-statuses-bar {
  display: flex;
  &__item {
    flex: 1;
    &--status {
      font-size: 16px;
      color: #2c2c2c;
      text-align: center;
      border-right: 1px solid #aaaaaa;
    }
    &--color {
      background-color: #2c2c2c;
      height: 30px;
      width: 100%;
      display: inline-block;
      margin-bottom: 15px;
    }
  }
}
</style>
