<template>
  <div>
    <div class="box-filter">
      <FilterAffiliate @changeAffiliate="changeAffiliate" :selectMultiple="false" />
      <FilterSeller @changeSeller="changeSeller" :selectMultiple="false" />
      <FilterStatus @changeStatus="changeStatus" />
      <FilterDatepicker @getDataSelect="setDataFilter" />
    </div>

    <div class="primary">
      <div class="box">
        <div class="panel w-100">
          <div class="panel__content">
            <div class="panel__header">
              <h2 class="panel__title panel__title--minor">
                Receitas dos
                <br />
                <b>pedidos no período</b>
              </h2>
            </div>
            <div class="panel__body">
              <div class="panel__numbers">
                <div class="panel__numbers__data --today">
                  <h4 class="panel__numbers__data__number">
                    <span class="number--medium">
                      {{ orders.valor_realizado | numberToReal }}
                    </span>

                    <span 
                      v-if="orders.compare"
                      :class="orders.compare.valor_realizado >= 0 ? 'badge badge--success' : 'badge'"
                      title="Comparado ao mesmo período do mês anterior"
                      v-b-tooltip.hover.top>{{orders.compare.valor_realizado | percentage}}</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel w-100">
          <div class="panel__content">
            <div class="panel__header">
              <h2 class="panel__title panel__title--minor">
                Total de
                <br />
                <b>pedidos</b>
              </h2>
            </div>
            <div class="panel__body">
              <div class="panel__numbers">
                <div class="panel__numbers__data --today">
                  <h4 class="panel__numbers__data__number">
                    <span class="number--medium">{{ orders.quantidade }}</span>

                    <span 
                      v-if="orders.compare"
                      :class="orders.compare.quantidade >= 0 ? 'badge badge--success' : 'badge'"
                      title="Comparado ao mesmo período do mês anterior"
                      v-b-tooltip.hover.top>{{orders.compare.quantidade | percentage}}</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel w-100">
          <div class="panel__content">
            <div class="panel__header">
              <h2 class="panel__title panel__title--minor">
                Média de itens
                <br />
                <b>por pedido</b>
              </h2>
            </div>
            <div class="panel__body">
              <div class="panel__numbers">
                <div class="panel__numbers__data --today">
                  <h4 class="panel__numbers__data__number">
                    <span class="number--medium">{{ orders.quantidade_itens_medio | numberToRealWitoutSymbol }}</span>

                    <span 
                      v-if="orders.compare"
                      :class="orders.compare.quantidade_itens_medio >= 0 ? 'badge badge--success' : 'badge'"
                      title="Comparado ao mesmo período do mês anterior"
                      v-b-tooltip.hover.top>{{orders.compare.quantidade_itens_medio | percentage}}</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel w-100">
          <div class="panel__content">
            <div class="panel__header">
              <h2 class="panel__title panel__title--minor">
                Ticket médio
                <br />
                <b>do pedido</b>
              </h2>
            </div>
            <div class="panel__body">
              <div class="panel__numbers">
                <div class="panel__numbers__data --today">
                  <h4 class="panel__numbers__data__number">
                    <span class="number--medium">R$ {{ orders.ticket_medio | numberToRealWitoutSymbol }}</span>

                    <span 
                      v-if="orders.compare"
                      :class="orders.compare.ticket_medio >= 0 ? 'badge badge--success' : 'badge'"
                      title="Comparado ao mesmo período do mês anterior"
                      v-b-tooltip.hover.top>{{orders.compare.ticket_medio | percentage}}</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="primary">
      <div class="box table" v-if="!status">
        <div class="panel w-100">
          <div class="panel__content">
            <div class="panel__header">
              <h2 class="panel__title panel__title--minor">
                Status
                <br />
                <b>dos pedidos</b>
              </h2>
            </div>
          </div>
        </div>
        <div v-if="ordersStatuses.items && ordersStatuses.items.length > 0">
          <lineStatuses :statuses="ordersStatuses.items" :total="orders.quantidade" />
          <p>
            Devido aos status mapeados pela plataforma. Os pedidos podem estar em qualquer outro status até chegarem em
            faturado ou cancelamento.
          </p>
        </div>
        <p class="empty" v-else>Sem dados</p>
      </div>

      <div :class="(status) ? 'box table w-100' : 'box table w-30'">
        <div class="panel">
          <div class="panel__content">
            <div class="panel__header">
              <h2 class="panel__title panel__title--minor">
                Formas de
                <br />
                <b>Pagamento</b>
              </h2>
            </div>
          </div>
        </div>

        <div v-if="ordersPayments.items && ordersPayments.items.length > 0">
          <div class="v-table">
            <div class="v-table__row" v-for="(payment, index) in ordersPayments.items" :key="index">
              <div class="v-table__cell">
                <IconCardCredit v-if="payment.paymentNames !== ('pix' || 'boleto' || 'debito')" />
                <IconBillet v-if="payment.paymentNames === 'boleto'" />
                <IconPix v-if="payment.paymentNames === 'pix'" />
                <IconDebitAccount v-if="payment.paymentNames === 'Débito em conta'" />
                <IconCustomizedPayment v-if="payment.paymentNames === 'Pagamento Customizados'" />
              </div>
              <div class="v-table__cell">
                {{ payment.paymentNames }}
              </div>
              <div class="v-table__cell">{{ ((payment.qtd_pedidos * 100) / orders.quantidade).toFixed() }}%</div>
              <div class="v-table__cell" style="min-width: 100px;">R$ {{ payment.valor_vendido | numberToRealWitoutSymbol }}</div>
            </div>
          </div>
        </div>

        <p class="empty" v-else>Sem dados</p>
      </div>
    </div>

    <div class="primary">
      <div class="box table">
        <div class="box__header">
          <HeaderGraphic :data="tableData" />
          <a v-if="tableData.items && tableData.items.length > 0" class="btn-download" @click="downloadCSV()" download
            >Download CSV</a
          >
        </div>
        <div class="painel" v-if="tableData.items && tableData.items.length > 0">
          <b-table :items="tableData.items">
            <template #cell(status)="data">
              <span class="table-dot" :style="{ backgroundColor: setColor(data.item.Status) }"></span>
              {{ setStatusDescription(data.item.Status) }}
            </template>
            <template #cell(cupom)="data">
              <span class="badge badge-outline">{{ data.item.Cupom }}</span>
            </template>
          </b-table>
          <paginate
            v-model="currentPage"
            :page-count="lastPage"
            :prev-text="'‹'"
            :next-text="'›'"
            :container-class="'pagination'"
            :page-class="'page-item'"
          ></paginate>
        </div>
        <p class="empty" v-else>Sem dados</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FilterDatepicker from "@/components/filter-datepicker/FilterDatepicker.vue";
import HeaderGraphic from "@/components/header-graphic/HeaderGraphic.vue";
import IconCardCredit from "@/components/icones/IconCardCredit.vue";
import FilterSeller from "@/components/filter-seller/FilterSeller.vue";
import FilterStatus from "@/components/filter-status/FilterStatus.vue";
import FilterAffiliate from "@/components/filter-affiliate/FilterAffiliate.vue";
import IconBillet from "@/components/icones/IconBillet.vue";
import IconPix from "@/components/icones/IconPix.vue";
import IconDebitAccount from "@/components/icones/IconDebitAccount.vue";
import IconCustomizedPayment from "@/components/icones/IconCustomizedPayment.vue";
import lineStatuses from "@/components/line-statuses/lineStatuses.vue";

import { numberToReal, numberToRealWitoutSymbol } from "@/utils/money";
import { percentage } from "@/utils/masks";
import { filterDataHora } from "@/utils/date";
import { fetchOrders, fetchOrdersDetails, fetchOrdersPayments, fetchOrdersStatuses, getCSV } from "@/api/orders";
import { setColor, setStatusDescription } from "@/utils/status";

export default {
  filters: {
    numberToReal,
    numberToRealWitoutSymbol,
    percentage,
  },
  components: {
    FilterSeller,
    FilterStatus,
    FilterAffiliate,
    HeaderGraphic,
    FilterDatepicker,
    IconCardCredit,
    IconBillet,
    IconPix,
    IconDebitAccount,
    IconCustomizedPayment,
    lineStatuses,
  },
  data() {
    return {
      tableData: {},
      perPage: 15,
      currentPage: 1,
      start: null,
      end: null,
      sellerIds: null,
      affiliateIds: null,
      status: null,
    };
  },
  computed: {
    ...mapState({
      orders: (state) => state.orders.orders,
      ordersDetails: (state) => state.orders.ordersDetails,
      ordersPayments: (state) => state.orders.ordersPayments,
      ordersStatuses: (state) => state.orders.ordersStatuses,
      lastPage: (state) => state.orders.orders_last_page,
    }),
  },
  mounted() {
    this.fetchOrders(this.start, this.end, {
      seller_id: this.sellerIds,
      affiliate_id: this.affiliateIds,
      status: this.status
    });

    this.fetchOrdersPayments(this.start, this.end, {
      seller_id: this.sellerIds,
      affiliate_id: this.affiliateIds,
      status: this.status
    });

    this.fetchOrdersStatuses(this.start, this.end, {
      status: this.status
    });

    this.fetchOrdersDetails(
      this.start,
      this.start,
      this.perPage,
      this.currentPage,
      {
        seller_id: this.sellerIds,
        affiliate_id: this.affiliateIds,
        status: this.status
      }
    );
  },
  methods: {
    setColor,
    setStatusDescription,
    fetchOrders,
    fetchOrdersPayments,
    fetchOrdersDetails,
    fetchOrdersStatuses,
    getCSV,
    changeSeller(sellerIds) {
      this.currentPage = 1;
      this.sellerIds = sellerIds;
      
      this.fetchOrders(this.start, this.end, {
        seller_id: this.sellerIds,
        affiliate_id: this.affiliateIds,
        status: this.status
      });

      this.fetchOrdersPayments(this.start, this.end, {
        seller_id: this.sellerIds,
        affiliate_id: this.affiliateIds,
        status: this.status
      });

      this.fetchOrdersStatuses(this.start, this.end, {
        status: this.status
      });

      this.fetchOrdersDetails(
        this.start,
        this.end,
        this.perPage,
        this.currentPage,
        {
          seller_id: this.sellerIds,
          affiliate_id: this.affiliateIds,
          status: this.status
        }
      );
    },
    changeAffiliate(affiliateIds) {
      this.currentPage = 1;
      this.affiliateIds = affiliateIds;
      
      this.fetchOrders(this.start, this.end, {
        seller_id: this.sellerIds,
        affiliate_id: this.affiliateIds,
        status: this.status
      });

      this.fetchOrdersPayments(this.start, this.end, {
        seller_id: this.sellerIds,
        affiliate_id: this.affiliateIds,
        status: this.status
      });

      this.fetchOrdersStatuses(this.start, this.end, {
        status: this.status
      });

      this.fetchOrdersDetails(
        this.start,
        this.end,
        this.perPage,
        this.currentPage,
        {
          seller_id: this.sellerIds,
          affiliate_id: this.affiliateIds,
          status: this.status
        }
      );
    },
    changeStatus(data) {
      this.currentPage = 1;
      this.status = data;
      
      this.fetchOrders(this.start, this.end, {
        seller_id: this.sellerIds,
        affiliate_id: this.affiliateIds,
        status: this.status
      });

      this.fetchOrdersPayments(this.start, this.end, {
        seller_id: this.sellerIds,
        affiliate_id: this.affiliateIds,
        status: this.status
      });

      this.fetchOrdersStatuses(this.start, this.end, {
        status: this.status
      });

      this.fetchOrdersDetails(
        this.start,
        this.end,
        this.perPage,
        this.currentPage,
        {
          seller_id: this.sellerIds,
          affiliate_id: this.affiliateIds,
          status: this.status
        }
      );
    },
    setDataFilter({ start, end }) {
      (this.start = start), (this.end = end);
      this.fetchOrders(start, end, this.sellerIds, this.affiliateIds, this.status);

      this.fetchOrdersPayments(start, end, {
        seller_id: this.sellerIds,
        affiliate_id: this.affiliateIds,
        status: this.status
      });

      this.fetchOrdersStatuses(start, end, {
        status: this.status
      });

      this.fetchOrdersDetails(
        start,
        end,
        this.perPage,
        this.currentPage,
        {
          seller_id: this.sellerIds,
          affiliate_id: this.affiliateIds,
          status: this.status
        }
      );
    },
    downloadCSV() {
      this.getCSV(this.start, this.end);
    },
    loadTable(value) {
      const items = [];
      if (value.data) {
        value.data.map((line) => {
          items.push({
            "Nº de Pedido": line.orderId,
            Data: filterDataHora(line.creationDate),
            "Total de Itens": line.totalItems,
            "Método de pagamento": line.paymentNames,
            Valor: "R$ " + numberToRealWitoutSymbol(line.totalValue),
            Status: line.status,
            // Origem: line.origin,
            Canal: line.affiliateId,
            Cupom: line.coupon,
            "Origem Mídia": line.utmPartner,
            "Source Mídia": line.utmSource,
          });
        });
      }

      let data = {
        title: {
          top: "",
          name: "Pedidos",
        },
        items,
      };
      return (this.tableData = data);
    },
  },
  watch: {
    ordersDetails(newData) {
      if (newData) this.loadTable(newData);
    },
    currentPage(newPage) {
      this.currentPage = newPage;
      
      this.fetchOrders(this.start, this.end, {
        seller_id: this.sellerIds,
        affiliate_id: this.affiliateIds,
        status: this.status
      });
      
      this.fetchOrdersPayments(this.start, this.end, {
        seller_id: this.sellerIds,
        affiliate_id: this.affiliateIds,
        status: this.status
      });

      this.fetchOrdersStatuses(this.start, this.end, {
        status: this.status
      });

      this.fetchOrdersDetails(
        this.start,
        this.end,
        this.perPage,
        this.currentPage,
        {
          seller_id: this.sellerIds,
          affiliate_id: this.affiliateIds,
          status: this.status
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .multiselect__placeholder {
    margin-top: 3px;
  }
}
.empty {
  text-align: center;
  width: 100%;
  font-size: 16px;
  height: 100%;
  padding: 90px 0;
  display: flex;
  color: $red !important;
  align-items: center;
  justify-content: center;
}
.primary {
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
}
.box-filter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 15px;
  gap: 16px;
}

.box {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  background-color: #fff;
  padding: 18px;
  box-shadow: 0 3px 6px #c3d0e029;
  border-radius: 20px;
  @media screen and (max-width: 991px) {
    gap: 20px;
    padding: 20px 15px;
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
  &__header {
    display: flex;
    justify-content: space-between;
  }
  &.table {
    margin: 0;
    margin-top: 15px;
    flex-direction: column;
    height: auto;
    &:after {
      content: "";
      display: block;
    }
    td[aria-colindex="5"] {
      white-space: nowrap;
      text-align: right;
    }
    .painel {
      height: auto; 
    }
  }
  .painel {
    position: relative;
    height: 300px;
    display: block;
    overflow-x: auto;
  }
  .panel {
    &__content {
      padding: 0;
      background: none;
      box-shadow: none;
    }
    &__numbers {
      grid-template-columns: 2fr 0.5fr 1fr;
    }
    &__numbers__data__number {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    &__header {
      @media screen and (max-width: 991px) {
        min-height: 1010px;
      }
      @media screen and (max-width: 600px) {
        min-height: 60px;
      }
    }
  }

  .number {
    &--medium {
      font-size: 25px;
    }
  }
}

.v-table {
  display: inline-block;
  width: 100%;
  margin-top: 35px;
  &__row {
    display: grid;
    grid-template-columns: 0.1fr 1.3fr 0.3fr 0.5fr;
    align-items: center;
    margin-top: 0.9rem;
    gap: 15px;
    padding-bottom: 18px;
    border-bottom: 1px solid #e6e6e6;
    font-weight: bold;
    color: #2c2c2c;
    font-size: 16px;
    &:last-child {
      border-bottom: none;
    }
  }
  &__cell {
    &:last-child {
      text-align: right;
    }
  }
}
</style>
