<template>
  <div class="filter-status">
    <p>Status:</p>
    <div>
      <b v-if="!loaded">Carregando...</b>
      <multiselect
        v-if="loaded && statusList.length > 0"
        class="filter-status__select"
        placeholder="Digite aqui para filtrar"
        noOptions="Sem resultados"
        label="statusDescription"
        track-by="id"
        v-model="selected"
        :options="statusList"
        :searchable="true"
        :allow-empty="true"
        :show-labels="false"
        :hide-selected="selectMultiple"
        :multiple="selectMultiple"
        :close-on-select="!selectMultiple"
        @remove="setSelected"
        @input="setSelected"
      ></multiselect>

      <b-button v-if="selected !== null" variant="outline-dark" size="sm" @click="clearFilter">
        limpar
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getFilterStatus } from "@/api/filters";
export default {
  data() {
    return {
      selected: null,
      loaded: false,
    };
  },
  props: ["selectMultiple"],
  mounted() {
    this.getFilterStatus();
  },
  computed: {
    ...mapState({
      statusList: (state) => state.filters.status,
    }),
  },
  methods: {
    getFilterStatus,
    setSelected() {
      this.$emit("changeStatus", this.selected.status);
    },
    clearFilter() {
      this.selected = null;
      this.$emit("changeStatus", null);
    },
  },
  watch: {
    statusList() {
      this.loaded = true;
    },
  },
};
</script>

<style lang="scss">
.filter-status {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  &__select {
    min-width: 200px;
    margin: 0 5px;
  }
  > div {
    align-items: center;
    display: flex;
    font-size: 14px;
    height: 40px;
    .btn {
      height: 30px;
    }
  }
}
</style>
