import client from "@/api";
import store from "@/store";
import { _rotaGetFilterStatus } from "@/api/_caminhosApi";

export function getFilterStatus() {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("filters/RESET_STATUS_LIST", null);
  client
    .get(_rotaGetFilterStatus())
    .then((resp) => {
      store.commit("filters/SET_STATUS_LIST", resp.data);
    })
    .catch((erro) => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
